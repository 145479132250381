import React, { useEffect, useContext, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import { useParams, useNavigate } from 'react-router-dom';
import Api from '../../config/Api.jsx';
import config from '../../config/config.js';

function Logingin() {
	const context = useContext(AppSettings);
	const navigate=useNavigate()
	let params=useParams()
	useEffect(() => {
		sessionStorage.setItem('nuzulul',params.blank)
		// Api.get('/kecrut',{
		// 	headers:{
		// 			Authorization:`Bearer ${sessionStorage.getItem('nuzulul')}`
		// 			}
		// 	})
		// .then((res)=>{
		// 	if(res.data.status==='ok'){
		// 		sessionStorage.setItem('bbw',res.data.user)
		// 		navigate('/dashboard')
		// 	}else{
		// 		window.location.replace(config.mainURL)
		// 	}
		// })
		// .catch((err)=>{
		// 	window.location.replace(config.mainURL)
		// })
		// navigate('/dashboard')

		setTimeout(()=>{
			navigate('/kpi')
		},100)
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
		
		// eslint-disable-next-line
	}, []);
	

  

	return (
		<div className="login">
			<div className="login-content">
				
					<h1 className="text-center">Redirecting</h1>
					<div className="text-inverse text-opacity-50 text-center mb-4">
						Please Wait....
					</div>
					
					<div className="text-center text-inverse text-opacity-50">
						Plant People Development Jobsite Maruwai Coal Operation
					</div>
			
			</div>
		</div>
	)
}

export default Logingin;