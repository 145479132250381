import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import BadgeSuccess from "../../components/badgeBorder/successBadge.jsx";
import BadgeDanger from "../../components/badgeBorder/dangerBadge.jsx";
import { Link } from "react-router-dom";
import ModalAddParticipantTraining from "../../components/modal/modalAddParticipantTraining.jsx";
import axiosInstance from "../../config/axios.js";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalEditParticipantTraining from "../../components/modal/modalEditParticipantTraining.jsx";

function ReportParticipant() {
  const [id, submitedEvent, setSubmitedEvent, setId, setWizardData] = useOutletContext();
  const [modalAddParticipant, setModalAddParticipant] = useState({ show: false });
  const [modalConfirm, setModalConfirm] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalDeletePesertaConfirm, setModalDeletePesertaConfirm] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalEditPart, setModalEditPart] = useState({ show: false, data: {}, headerFetch: {} });
  const [table, setTable] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function handleAddParticipant() {
    setModalAddParticipant({ ...modalAddParticipant, show: true });
  }
  function handleSubmitNewPart(val) {
    val = { ...val, id: id };
    setModalAddParticipant({ ...modalAddParticipant, show: false });
    axiosInstance
      .post("trainingSubmit/newParticipant", val, getHeader())
      .then((res) => {
        if (res.data.status) {
          axiosInstance
            .get("/training/peserta/" + id, getHeader())
            .then((res) => setTable(res.data.data))
            .catch((e) => console.log("need relogin"));
        }
      })
      .catch((e) => console.log("need relogin"));
  }

  function handleDeletePeserta(val) {
    setModalDeletePesertaConfirm({ ...modalDeletePesertaConfirm, show: true, id: val, text: "Are you sure want to delete this participant ?" });
  }

  function handleConfirmDeleteParticipant() {
    setModalDeletePesertaConfirm({ ...modalDeletePesertaConfirm, show: false });
    console.log(modalDeletePesertaConfirm.data);
    axiosInstance
      .delete("trainingDelete/deletePeserta/" + modalDeletePesertaConfirm.id, getHeader())
      .then((res) => {
        if (res.data.status) {
          axiosInstance
            .get("/training/peserta/" + id, getHeader())
            .then((res) => setTable(res.data.data))
            .catch((e) => console.log("need relogin"));
        }
      })
      .catch((e) => console.log("need relogin"));
  }

  function handleEditPeserta(val) {
    setModalEditPart({ ...modalEditPart, show: true, data: val });
  }

  function handleEditSubmit(val) {
    setModalEditPart({ ...modalEditPart, show: false });
    axiosInstance
      .put("training-edit/peserta", val, getHeader())
      .then((res) => {
        if (res.data.status) {
          axiosInstance
            .get("/training/peserta/" + id, getHeader())
            .then((res) => setTable(res.data.data))
            .catch((e) => console.log("need relogin"));
        }
      })
      .catch((e) => console.log("need relogin"));
  }

  function eventSubmit() {
    navigate("/training/report/document");
  }

  useEffect(() => {
    setWizardData([
      { label: "Event", status: "completed" },
      { label: "Participant", status: "active" },
      { label: "Document", status: "disabled" },
      { label: "Media", status: "disabled" },
    ]);
  }, []);
  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={table.length > 0 ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>
      <hr className="mb-4" />

      <div className="mb-2">
        <div className="d-block d-lg-flex align-items-center">
          <span onClick={() => handleAddParticipant()} className="btn btn-outline-theme me-2 mb-1">
            <i className="fa fa-fw fa-plus"></i>
            <span>Add Part..</span>
          </span>
        </div>
      </div>

      {table.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th className="border-top-0 pt-0 pb-2">No.</th>
                <th className="border-top-0 pt-0 pb-2">Name</th>
                <th className="border-top-0 pt-0 pb-2">Company</th>
                <th className="border-top-0 pt-0 pb-2">Position</th>
                <th className="border-top-0 pt-0 pb-2">Pre Test</th>
                <th className="border-top-0 pt-0 pb-2">Post Test</th>
                <th className="border-top-0 pt-0 pb-2">Practice</th>
                <th className="border-top-0 pt-0 pb-2">Status</th>
                <th className="border-top-0 pt-0 pb-2">Remark</th>
                <th className="border-top-0 pt-0 pb-2"></th>
              </tr>
            </thead>
            <tbody>
              {table.map((par, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="align-middle">
                    <Link to={"#/"}>{par.nama}</Link>
                  </td>
                  <td className="align-middle">{par.perusahaan}</td>
                  <td className="align-middle">{par.jabatanFn}</td>
                  <td className="align-middle">{par.pre}</td>
                  <td className="align-middle">{par.post}</td>
                  <td className="align-middle">{par.practice}</td>
                  <td className="align-middle">{par.result == "Pass" || par.result == "PASS" ? <BadgeSuccess text={par.result} /> : <BadgeDanger text={par.result} />}</td>
                  <td className="align-middle">{par.remark}</td>
                  <td>
                    <a href="#/" onClick={() => handleDeletePeserta(par.id)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-trash-alt text-danger"></i>
                    </a>
                    <a href="#/" onClick={() => handleEditPeserta(par)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-edit text-theme"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No Participant</p>
      )}

      <hr className="mb-4" />

      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0"></div>
        <div className="pagination mb-0 justify-content-center">
          {spinner ? (
            <div className="spinner-border"></div>
          ) : (
            <span onClick={() => eventSubmit()} className={table.length > 0 ? "btn btn-outline-theme me-2 mb-1" : "btn btn-outline-theme me-2 mb-1 disabled"}>
              <span>Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          )}
        </div>
      </div>

      <ModalAddParticipantTraining show={modalAddParticipant.show} data={modalAddParticipant.data} submitClick={(val) => handleSubmitNewPart(val)} onHide={() => setModalAddParticipant({ ...modalAddParticipant, show: false })} />
      <ModalConfirm
        show={modalDeletePesertaConfirm.show}
        clicked={() => handleConfirmDeleteParticipant()}
        onHide={() => setModalDeletePesertaConfirm({ ...modalDeletePesertaConfirm, show: false })}
        title={modalDeletePesertaConfirm.title}
        text={modalDeletePesertaConfirm.text}
        theme={"danger"}
      />
      <ModalEditParticipantTraining show={modalEditPart.show} data={modalEditPart.data} submitClick={(e) => handleEditSubmit(e)} onHide={() => setModalEditPart({ ...modalEditPart, show: false })} />
    </>
  );
}

export default ReportParticipant;
