import React, { useEffect, useState } from 'react';
import { Card , CardBody} from './../../components/card/card.jsx';
import Pagination1 from '../../components/pagination/pagination1.jsx';
import { Link } from 'react-router-dom';
import axiosInstance from '../../config/axios.js';

const trainingPresetData={
	currentPage:1,
	data:{
		data:null
	},
	from:1,
	path:null,
	to:0,
	total:0
}

function DataTraining() {
	const [trainingAll,setTrainingAll]=useState(trainingPresetData)
	const [search1Value,setSearch1Value]=useState('')
	const [miniResume,setMiniResume]=useState(null)

	function getHeader(){
			const headers={
				headers:{
					Authorization:`Bearer ${sessionStorage.getItem('nuzulul')}`
				}
				}
			return headers
	}

	function nextClick1(){
		const page=trainingAll.currentPage+1
		axiosInstance.get('/training/all?search='+search1Value+'&page='+page,getHeader()).then(res=>setTrainingAll(res.data.data)).catch((e)=>console.log('need relogin'))
	}
	function prevClick1(){
		const page=trainingAll.currentPage-1
		axiosInstance.get('/training/all?search='+search1Value+'&page='+page,getHeader()).then(res=>setTrainingAll(res.data.data)).catch((e)=>console.log('need relogin'))
	}
	function submitSearch1(e){
		e.preventDefault()
		axiosInstance.get('/training/all?search='+search1Value,getHeader()).then(res=>setTrainingAll(res.data.data)).catch((e)=>console.log('need relogin'))
	}

	


	useEffect(()=>{
		axiosInstance.get('/training/all',getHeader()).then(res=>setTrainingAll(res.data.data)).catch((e)=>console.log('need relogin'))

	},[])

	return (
		<>
		<div>
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">TRAINING</a></li>
				<li className="breadcrumb-item active">Data</li>
			</ul>
	
			<h1 className="page-header">
				Training Records <small></small>
			</h1>
	
			<hr className="mb-4" />
			{miniResume?
				<div className="mb-md-4 mb-3 d-md-flex">
					{/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Export</a></div> */}
					<div className="ms-md-4 mt-md-0 mt-2"><i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.monthly.new.qty+' Monthly'}</div>
					<div className="ms-md-4 mt-md-0 mt-2"><i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.monthly.out.qty+' Monthly'}</div>
					<div className="ms-md-4 mt-md-0 mt-2"><i className="fas fa-user-plus fa-fw fa-lg me-1 text-theme"></i> {miniResume.yearly.new.qty+' Yearly'}</div>
					<div className="ms-md-4 mt-md-0 mt-2"><i className="fas fa-user-times user-times fa-lg me-1 text-danger"></i> {miniResume.yearly.out.qty+' Yearly'}</div>
				</div>
				:''
			}
			<Card>
				<div className="tab-content p-4">

			{/* ----------------All Tab------------------------------------------------------------------ */}
					<div className="tab-pane fade show active" id="allTab">
						<form onSubmit={(e)=>submitSearch1(e)}>
							<div className="input-group mb-4">
									<div className="d-flex justify-content-end">
										<div className="input-group">
											<input type="text" onChange={(e)=>setSearch1Value(e.target.value)} className="form-control px-35px" placeholder="Search" />
											<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 1020}}>
												<i className="fa fa-search opacity-5"></i>
											</div>
										</div>
									</div>
									<button className="btn btn-outline-default" type="submit"><i className='fas fa-search'></i></button>
							</div>
						</form> 

						{/* ----------------Table-------------- */}

						<div className="table-responsive">
							<table className="table table-hover text-nowrap">
								<thead>
									<tr>
										<th className="border-top-0 pt-0 pb-2">Training Title</th>
										<th className="border-top-0 pt-0 pb-2">Location</th>
										<th className="border-top-0 pt-0 pb-2">Institution</th>
										<th className="border-top-0 pt-0 pb-2">Instructor</th>
										<th className="border-top-0 pt-0 pb-2">Date</th>
									</tr>
								</thead>
								<tbody>
									{trainingAll.data.data?
									trainingAll.data.data.map((value,index)=>(
										<tr key={index}>
											<td className="align-middle"><Link to={'/training/detail/'+value.id}>{value.judul}</Link></td>
											<td className="align-middle">{value.lokasiTraining}</td>
											<td className="align-middle">{value.lembaga}</td>
											<td className="align-middle">{value.namaInstructor}</td>
											<td className="align-middle">{value.start}</td>
											
										</tr>
									))
									:
											<tr>
											<td className="align-middle">no Data</td>
											<td className="align-middle"></td>
											<td className="align-middle"></td>
											<td className="align-middle"></td>
											<td className="align-middle"></td>
											</tr>}
								</tbody>
							</table>
						</div>
						{/* ----------------End Table-------------- */}

						{trainingAll?	
						<Pagination1 pageInfo={trainingAll} nextClick={nextClick1} prevClick={prevClick1}/>	
						:""}
					</div>
			{/* ----------------End All Tab------------------------------------------------------------------ */}
	
				
			</div>

		</Card>



		</div>
		</>
	)
}

export default DataTraining