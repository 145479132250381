import React, { useContext, useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "../../components/card/card.jsx";
import "lity";
import "lity/dist/lity.min.css";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getImageSize } from "react-image-size";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import ModalUploadImage from "../../components/modal/modalUploadImage.jsx";
import ModalLoading from "../../components/modal/modalLoading.jsx";
import axiosInstance from "../../config/axios.js";
import ModalUploadFile from "../../components/modal/modalUploadFile.jsx";
import ModalInfo from "../../components/modal/modalInfo.jsx";

const dataModal = {
  title: "Upload New Image",
  text: "Availabel image format *.JPG, *.PNG",
  data: {
    id: null,
    val: null,
  },
};

const fileModalText = {
  title: "Upload New File",
  text: "lshdflkashjdlfkasjd",
  data: {
    id: null,
    val: null,
  },
};

function MpDetail() {
  const [profile, setProfile] = useState();
  const [mainProfile, setMainProfile] = useState();
  const [trainings, setTrainings] = useState([]);
  const [modalInfoShow, setModalInfoShow] = useState(false);
  const [modalInfoText, setModalInfoText] = useState("blank");
  const [modalConfirm, setModalConfirm] = useState({ show: false });
  const [modalUploadImageShow, setModalUploadImageShow] = useState(false);
  const [modalUploadFileShow, setModalUploadFileShow] = useState(false);
  const [conDeleteImgMOdal, setConDeleteImgMOdal] = useState(false);
  const [conDeleteFileModal, setConDeleteFileMOdal] = useState(false);
  const [modalImageData, setModalImageData] = useState(dataModal);
  const [modalFileData, setModalFileData] = useState(fileModalText);
  const [personalGaleries, setPersonalGaleries] = useState([]);
  const [imgCheckBox, setImgCheckBox] = useState(false);
  const [documentDatas, setDocumentDatas] = useState([]);
  const [selected, setSelected] = useState([]);
  const [imgPaths, setImgPaths] = useState([]);
  const [imgDelModalText, setImgDelModalText] = useState("No media are selected, are you sure to continue ?");
  const [modalProggress, setModalProggress] = useState(false);
  const [textModalProggress, setTextModalProggress] = useState({ title: "title", text: "text" });
  const [proggressValue, setProggressValue] = useState(0);
  const [fileDelete, setFileDelete] = useState();
  const navigate = useNavigate();
  let params = useParams();

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
      validateStatus: false,
    };
    return headers;
  }

  function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function deleteConfirm(data) {
    setModalConfirm({ show: false });
    if (data) navigate("/manpower/data");
  }
  function confirmButton() {
    axiosInstance
      .delete("/manpowerv2delete/" + params.id, getHeader())
      .then((res) => deleteConfirm(res.data.data))
      .catch((e) => console.log("need relogin"));
  }
  function handleDelete() {
    setModalConfirm({ show: true });
  }

  function handleCancleSelected() {
    setImgCheckBox(false);
    setSelected([]);
  }

  function handleClickUploadImage() {
    setModalImageData({
      title: "Upload New Image",
      text: "Availabel image format *.JPG, *.PNG",
      data: {
        id: params.id,
        val: null,
      },
    });
    setModalUploadImageShow(true);
  }

  function getSize(imgs) {
    setPersonalGaleries(imgs);
    imgs.forEach(async (img) => {
      try {
        const dimension = await getImageSize(img.path);
        setImgPaths((a) => [...a, { width: dimension?.width, height: dimension?.height }]);
      } catch (error) {
        setImgPaths((a) => [...a, { width: 752, height: 502 }]);
      }
    });
  }

  function handleImageSubmit(e) {
    setModalUploadImageShow(false);
    setModalProggress(true);
    setTextModalProggress({ title: "Uploading", text: "Uploading image please wait" });

    axiosInstance
      .post("/postImagePersonalGalery", e, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setProggressValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        if (res.data.status) {
          setModalProggress(false);
        }
        setImgPaths([]);
        axiosInstance
          .get("/getPersonalGalery/" + params.id)
          .then((res2) => getSize(res2.data.data))
          .catch((e) => console.log("need relogin"));
      })
      .catch((e) => console.log("need relogin"));
  }
  function handleCheckBoxChange(e) {
    if (e.target.checked) {
      setSelected((a) => [...a, { id: e.target.id }]);
    } else {
      setSelected(selected.filter((a) => a.id !== e.target.id));
    }
  }

  function handleDeleteImgConfirm() {
    const as = selected;
    setSelected([]);
    as.forEach(async (a) => {
      const res = await axiosInstance.delete("/delpersonalgalery/" + a.id, getHeader());
      setImgCheckBox(false);
      setImgPaths([]);
      axiosInstance
        .get("/getPersonalGalery/" + params.id)
        .then((res) => getSize(res.data.data))
        .catch((e) => console.log("need relogin"));
    });
    setConDeleteImgMOdal(false);
  }

  function handleModalImgDel() {
    const qty = selected.length;
    if (qty) {
      setImgDelModalText("Are you sure want to delete " + qty + " item selected ?");
    } else {
      setImgDelModalText("No media are selected, are you sure to continue ?");
    }
    setConDeleteImgMOdal(true);
  }

  function handleModalFileShow() {
    setModalFileData({
      title: "Upload New File",
      text: "Upload files related to manpower such as personal data, couching counseling, certificates, etc",
      data: {
        id: params.id,
        val: null,
      },
    });
    setModalUploadFileShow(true);
  }

  function handleFileSubmit(e) {
    setModalUploadFileShow(false);
    setTextModalProggress({ title: "Uploading", text: "Uploading file please wait" });
    setModalProggress(true);

    axiosInstance
      .post("/uploadManpowerDocument", e, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setProggressValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        if (res.data.status == "success") {
          setModalProggress(false);
        }
        if (res.data.status == "error") {
          setModalProggress(false);
          setModalInfoText("File already exist cuy !!");
          setModalInfoShow(true);
        }
        axiosInstance
          .get("/getInfoManpowerDocument/" + params.id, getHeader())
          .then((res) => setDocumentDatas(res.data.data))
          .catch((e) => console.log("need relogin"));
      });
  }

  function handleDeleteFile(id) {
    setFileDelete(id);
    setConDeleteFileMOdal(true);
  }

  function handleDeleteFileConfirm() {
    setConDeleteFileMOdal(false);
    axiosInstance
      .delete("deleteManpowerDocument/" + fileDelete, getHeader())
      .then((res) => {
        if (res.data.status) {
          axiosInstance
            .get("/getInfoManpowerDocument/" + params.id, getHeader())
            .then((res) => setDocumentDatas(res.data.data))
            .catch((e) => console.log("need relogin"));
        }
      })
      .catch((e) => console.log("need relogin"));
  }

  async function handleDownloadFileDocument(id) {
    try {
      const response = await axiosInstance.get("downloadManpowerDocument/" + id, { ...getHeader(), responseType: "blob" });
      const metaData = await axiosInstance.get("getFileNameManpowerDocument/" + id, getHeader());
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", metaData.data.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {}
  }

  useEffect(() => {
    axiosInstance
      .get("/manpowerv2/profile?id=" + params.id, getHeader())
      .then((res) => setProfile(res.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/manpowerv2/mainProfile?id=" + params.id, getHeader())
      .then((res) => setMainProfile(res.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/manpowerv2/trainings?id=" + params.id, getHeader())
      .then((res) => setTrainings(res.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/getPersonalGalery/" + params.id)
      .then((res) => getSize(res.data.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/getInfoManpowerDocument/" + params.id, getHeader())
      .then((res) => setDocumentDatas(res.data.data))
      .catch((e) => console.log("need relogin"));

    var lightbox;
    if (!lightbox) {
      lightbox = new PhotoSwipeLightbox({
        gallery: ".gallery-image-list",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
    }

    return function cleanUp() {
      if (lightbox) {
        lightbox.destroy();
        lightbox = null;
      }
    };
  }, []);

  return (
    <>
      <Card>
        <CardBody className="p-0">
          <div className="profile">
            <div className="profile-container">
              <div className="profile-sidebar">
                <div className="desktop-sticky-top">
                  {mainProfile ? (
                    <>
                      <div className="profile-img">
                        <img src={mainProfile.profileImage} alt="" />
                      </div>

                      <h4 className="text-truncate">{capitalizeFirstLetter(mainProfile.name)}</h4>
                      <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">{mainProfile.nrp}</div>
                      <p>{mainProfile.about1}</p>
                      <div className="mb-1">
                        <i className="fa fa-map-marker-alt fa-fw text-inverse text-opacity-50"></i> {mainProfile.phone}
                      </div>
                      <div className="mb-3 text-truncate">
                        <i className="fa fa-link fa-fw text-inverse text-opacity-50"></i> {mainProfile.email}
                      </div>

                      <hr className="mt-4 mb-4" />

                      {mainProfile ? (
                        <>
                          <div className="fw-bold mb-3 fs-16px">Development</div>
                          {mainProfile.development.map((dev, i) => (
                            <div key={i}>
                              <div className="d-flex align-items-center mb-3">
                                <div className="flex-fill">
                                  <div className="fw-bold text-truncate">{dev.item}</div>
                                </div>
                                <div className="fw-bold text-theme">{dev.value}</div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    "No Data"
                  )}
                </div>
              </div>

              <div className="profile-content">
                <ul className="profile-tab nav nav-tabs nav-tabs-v2">
                  <li className="nav-item">
                    <a href="#profile" className="nav-link active" data-bs-toggle="tab">
                      <div className="nav-field">Profile</div>
                      {/* <div className="nav-value">382</div> */}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#profile-followers" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">Trainings</div>
                      {/* <div className="nav-value">1.3m</div> */}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#profile-media" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">Galery</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#profile-document" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">Documents</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#profile-options" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">Options</div>
                      {/* <div className="nav-value">120</div> */}
                    </a>
                  </li>
                </ul>
                <div className="profile-content-container">
                  <div className="row gx-4">
                    <div className="col-xl-8">
                      <div className="tab-content p-0">
                        <div className="tab-pane show active fade" id="profile">
                          {profile ? (
                            <>
                              {/* -----------------------profile basic------------------------ */}
                              <div className="profile-phone">
                                <Card>
                                  <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">General</CardHeader>
                                  <CardBody>
                                    {mainProfile ? (
                                      <>
                                        <div className="profile-img-phone">
                                          <img src={mainProfile.profileImage} alt="" />
                                        </div>

                                        <h4 className="text-truncate">{capitalizeFirstLetter(mainProfile.name)}</h4>
                                        <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">{mainProfile.nrp}</div>
                                        <p>{mainProfile.about1}</p>
                                        <div className="mb-1">
                                          <i className="fa fa-map-marker-alt fa-fw text-inverse text-opacity-50"></i> {mainProfile.phone}
                                        </div>
                                        <div className="mb-3 text-truncate">
                                          <i className="fa fa-link fa-fw text-inverse text-opacity-50"></i> {mainProfile.email}
                                        </div>

                                        <hr className="mt-4 mb-4" />

                                        {mainProfile ? (
                                          <>
                                            <div className="fw-bold mb-3 fs-16px">Development</div>
                                            {mainProfile.development.map((dev, i) => (
                                              <div key={i}>
                                                <div className="d-flex align-items-center mb-3">
                                                  <div className="flex-fill">
                                                    <div className="fw-bold text-truncate">{dev.item}</div>
                                                  </div>
                                                  <div className="fw-bold text-theme">{dev.value}</div>
                                                </div>
                                              </div>
                                            ))}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      "No Data"
                                    )}
                                  </CardBody>
                                </Card>
                              </div>
                              <Card>
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Basic</CardHeader>
                                <CardBody>
                                  <table className="table table-borderless table-sm m-0">
                                    <tbody>
                                      {profile.basic.map((value, index) => (
                                        <tr key={index}>
                                          <td className="w-150px">{value.item}</td>
                                          <td className="text-theme">{value.value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                              {/* -----------------------end profile basic------------------------ */}
                              {/* -----------------------profile History------------------------ */}
                              <Card>
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
                                  History
                                  {/* <a href="#/" className="ms-auto text-decoration-none text-inverse text-opacity-50">Edit</a> */}
                                </CardHeader>
                                <CardBody>
                                  <table className="table table-borderless table-sm m-0">
                                    <tbody>
                                      {profile.history.map((value, index) => (
                                        <tr key={index}>
                                          <td className="w-150px">{value.item}</td>
                                          <td className="text-theme">{value.value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                              {/* -----------------------end profile Hostory------------------------ */}
                              {/* -----------------------profile Contacts------------------------ */}
                              <Card>
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
                                  Contacts
                                  {/* <a href="#/" className="ms-auto text-decoration-none text-inverse text-opacity-50">Edit</a> */}
                                </CardHeader>
                                <CardBody>
                                  <table className="table table-borderless table-sm m-0">
                                    <tbody>
                                      {profile.contacts.map((value, index) => (
                                        <tr key={index}>
                                          <td className="w-150px">{value.item}</td>
                                          <td className="text-theme">{value.value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                              {/* -----------------------end profile Contacts------------------------ */}
                              {/* -----------------------profile Career------------------------ */}
                              <Card>
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
                                  Career and Development
                                  {/* <a href="#/" className="ms-auto text-decoration-none text-inverse text-opacity-50">Edit</a> */}
                                </CardHeader>
                                <CardBody>
                                  <table className="table table-borderless table-sm m-0">
                                    <tbody>
                                      {profile.career.map((value, index) => (
                                        <tr key={index}>
                                          <td className="w-150px">{value.item}</td>
                                          <td className="text-theme">{value.value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                              {/* -----------------------end profile Career------------------------ */}
                              {/* -----------------------profile Career------------------------ */}
                              <Card>
                                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
                                  Additional
                                  {/* <a href="#/" className="ms-auto text-decoration-none text-inverse text-opacity-50">Edit</a> */}
                                </CardHeader>
                                <CardBody>
                                  <table className="table table-borderless table-sm m-0">
                                    <tbody>
                                      {profile.additional.map((value, index) => (
                                        <tr key={index}>
                                          <td className="w-150px">{value.item}</td>
                                          <td className="text-theme">{value.value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                              {/* -----------------------end profile Career------------------------ */}
                            </>
                          ) : (
                            "No data"
                          )}
                        </div>
                        <div className="tab-pane fade" id="profile-followers">
                          <Card>
                            <CardBody>
                              {trainings.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table table-hover text-nowrap">
                                    <thead>
                                      <tr>
                                        <th className="border-top-0 pt-0 pb-2"></th>
                                        <th className="border-top-0 pt-0 pb-2">Title</th>
                                        <th className="border-top-0 pt-0 pb-2">Institution</th>
                                        <th className="border-top-0 pt-0 pb-2">Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {trainings.map((training, index) => (
                                        <tr key={index}>
                                          <td className="align-middle">{index + 1}</td>
                                          <td className="align-middle">
                                            <Link to={"/training/detail/" + training.id}>{training.title}</Link>
                                          </td>
                                          <td className="align-middle">{training.institution}</td>
                                          <td className="align-middle">{training.date}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                "No Training"
                              )}
                            </CardBody>
                          </Card>
                        </div>
                        {/* -----------------------profile Media------------------------ */}
                        <div className="tab-pane fade" id="profile-media">
                          <Card>
                            <CardBody>
                              <div className="gallery-content-container">
                                <div className="gallery-content">
                                  <div className="gallery">
                                    <div className="d-flex align-items-center mb-3">
                                      <div className="gallery-title mb-0">
                                        <a href="#/">
                                          Personal <i className="fa fa-chevron-right"></i>
                                        </a>
                                      </div>

                                      <div className="ms-auto btn-group">
                                        {!imgCheckBox ? (
                                          <>
                                            <a href="#/" onClick={() => setImgCheckBox(true)} className="btn btn-outline-default btn-sm">
                                              <i className="fas fa-check-square"></i>
                                            </a>
                                            <a href="#/" onClick={() => handleClickUploadImage()} className="btn btn-outline-default btn-sm">
                                              <i className="fa fa-upload"></i>
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <a href="#/" onClick={() => handleModalImgDel()} className="btn btn-outline-default btn-sm">
                                              <i className="fas fa-trash-alt text-danger"></i>
                                            </a>
                                            <a href="#/" onClick={() => handleCancleSelected()} className="btn btn-outline-default btn-sm">
                                              X
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="gallery-image">
                                      <ul className="gallery-image-list">
                                        {personalGaleries && personalGaleries.length > 0 ? (
                                          personalGaleries.map((personalGalery, index) => (
                                            <li key={index}>
                                              {!imgCheckBox ? (
                                                <a href="#/" data-pswp-src={personalGalery.path} data-pswp-width={imgPaths[index] ? imgPaths[index].width : "752"} data-pswp-height={imgPaths[index] ? imgPaths[index].height : "502"}>
                                                  <img src={personalGalery.path} className="img-portrait" />
                                                </a>
                                              ) : (
                                                <div className="select-mode">
                                                  <span className="position-absolute top-50 start-50 translate-middle p-2 bg-dark border border-light rounded-pill">
                                                    <input id={personalGalery.id} onChange={(e) => handleCheckBoxChange(e)} className="form-check-input" type="checkbox" />
                                                  </span>
                                                  <img src={personalGalery.path} className="img-portrait" />
                                                </div>
                                              )}
                                            </li>
                                          ))
                                        ) : (
                                          <p>No Image</p>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        {/* -----------------------end profile Media------------------------ */}

                        {/* -----------------------profile Document------------------------ */}
                        <div className="tab-pane fade" id="profile-document">
                          <Card className="mb-3">
                            <CardHeader className="fw-bold bg-transparent">
                              <div className="d-flex align-items-center">
                                <div className="gallery-title mb-0">Attached Documents</div>
                                <div className="ms-auto btn-group">
                                  <a href="#/" onClick={() => handleModalFileShow()} className="btn btn-outline-default btn-sm">
                                    <i className="fa fa-upload text-theme"></i>
                                  </a>
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <DocumentTable data={documentDatas} handleDownloadFileDocument={(id) => handleDownloadFileDocument(id)} handleDeleteFile={(id) => handleDeleteFile(id)} />
                            </CardBody>
                          </Card>
                        </div>
                        {/* -----------------------end profile document------------------------ */}

                        {/* -----------------------profile options------------------------ */}
                        <div className="tab-pane fade" id="profile-options">
                          <Card className="mb-3">
                            <CardBody>
                              <div className="mb-3">
                                <Link to={"/manpower/edit/" + params.id}>Edit Profile</Link>
                              </div>
                              <div className="mb-3">
                                <Link to="#" onClick={() => handleDelete()} className="text-danger">
                                  Delete
                                </Link>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        {/* -----------------------end profile options------------------------ */}
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="desktop-sticky-top d-none d-lg-block">
                        <Card className="mb-3">
                          <div className="list-group list-group-flush">
                            <div className="list-group-item fw-bold px-3 d-flex">
                              <span className="flex-fill">Latest Development</span>
                              <a href="#/" className="text-inverse text-opacity-50">
                                <i className="fa fa-cog"></i>
                              </a>
                            </div>
                            <div className="list-group-item px-3">
                              {/* <div className="text-inverse text-opacity-50"><small><strong>Trending Worldwide</strong></small></div> */}
                              <div className="fw-bold mb-2">---------</div>
                              {/* <Card className="text-inverse text-decoration-none mb-1">
															<CardBody>
																<div className="row no-gutters">
																	<div className="col-md-8">
																		<div className="small text-inverse text-opacity-50 mb-1 mt-n1">Space</div>
																		<div className="h-40px fs-13px overflow-hidden mb-n1">Distant star explosion is brightest ever seen, study finds</div>
																	</div>
																	<div className="col-md-4 d-flex">
																		<div className="h-100 w-100" style={{ background: 'url(assets/img/gallery/news-1.jpg) center', backgroundSize: 'cover'}}></div>
																	</div>
																</div>
															</CardBody>
														</Card>
														<div><small className="text-inverse text-opacity-50">1.89m share</small></div> */}
                            </div>
                            {/* <div className="list-group-item px-3">
														<div className="fw-bold mb-2">#TrollingForGood</div>
														<div className="fs-13px mb-1">Be a good Troll and spread some positivity on HUD today.</div>
														<div><small className="text-inverse text-opacity-50"><i className="fa fa-external-link-square-alt"></i> Promoted by HUD Trolls</small></div>
													</div>
													<div className="list-group-item px-3">
														<div className="text-inverse text-opacity-50"><small><strong>Trending Worldwide</strong></small></div>
														<div className="fw-bold mb-2">#CronaOutbreak</div>
														<div className="fs-13px mb-1">The coronavirus is affecting 210 countries around the world and 2 ...</div>
														<div><small className="text-inverse text-opacity-50">49.3m share</small></div>
													</div>
													<div className="list-group-item px-3">
														<div className="text-inverse text-opacity-50"><small><strong>Trending in New York</strong></small></div>
														<div className="fw-bold mb-2">#CoronavirusPandemic</div>
														<Card className="mb-1 text-inverse text-decoration-none">
															<CardBody>
																<div className="row no-gutters">
																	<div className="col-md-8">
																		<div className="fs-12px text-inverse text-opacity-50 mt-n1">Coronavirus</div>
																		<div className="h-40px fs-13px overflow-hidden mb-n1">Coronavirus: US suspends travel from Europe</div>
																	</div>
																	<div className="col-md-4 d-flex">
																		<div className="h-100 w-100" style={{ background: 'url(assets/img/gallery/news-2.jpg) center', backgroundSize: 'cover'}}></div>
																	</div>
																</div>
															</CardBody>
														</Card>
														<div><small className="text-inverse text-opacity-50">821k share</small></div>
													</div> */}
                            {/* <a href="#/" className="list-group-item list-group-action text-center">
														Show more
													</a> */}
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <ModalConfirm show={modalConfirm.show} clicked={() => confirmButton()} onHide={() => setModalConfirm({ show: false })} title={"Warning"} text={"Are you sure want to delete this manpower data ?"} theme={"danger"} />
      <ModalUploadImage show={modalUploadImageShow} submitClick={(e) => handleImageSubmit(e)} data={modalImageData} onHide={() => setModalUploadImageShow(false)} />
      <ModalConfirm show={conDeleteImgMOdal} clicked={() => handleDeleteImgConfirm()} theme={"danger"} onHide={() => setConDeleteImgMOdal(false)} title={"Warning"} text={imgDelModalText} />
      <ModalConfirm show={conDeleteFileModal} clicked={() => handleDeleteFileConfirm()} theme={"danger"} onHide={() => setConDeleteFileMOdal(false)} title={"Warning"} text={"Are sure want to delete this file ?"} />
      <ModalLoading show={modalProggress} title={textModalProggress.title} text={textModalProggress.text} value={proggressValue} />
      <ModalUploadFile show={modalUploadFileShow} submitClick={(e) => handleFileSubmit(e)} data={modalFileData} onHide={() => setModalUploadFileShow(false)} />
      <ModalInfo show={modalInfoShow} clicked={() => setModalInfoShow(false)} text={modalInfoText} onHide={() => setModalInfoShow(false)} title={"Info"} />
    </>
  );
}

export default MpDetail;

function DocumentTable({ data = [], handleDeleteFile, handleDownloadFileDocument }) {
  const dateFormat = (d) => {
    const date = new Date(d);
    return date.toDateString();
  };

  return (
    <>
      {data.length ? (
        <div className="table-responsive">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th className="border-top-0 pt-0 pb-2">No</th>
                <th className="border-top-0 pt-0 pb-2">FileName</th>
                <th className="border-top-0 pt-0 pb-2">Size</th>
                <th className="border-top-0 pt-0 pb-2">Uploaded</th>
                <th className="border-top-0 pt-0 pb-2"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="align-middle text-theme">{value.fileName}</td>
                  <td className="align-middle">{value.size}</td>
                  <td className="align-middle">{dateFormat(value.updated_at)}</td>
                  <td className="align-middle">
                    <a href="#" onClick={() => handleDeleteFile(value.id)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-trash-alt text-danger"></i>
                    </a>
                    <a href="#" onClick={() => handleDownloadFileDocument(value.id)} className="btn btn-outline-default btn-sm">
                      <i className="fas fa-download text-theme"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>no data</p>
      )}
    </>
  );
}
