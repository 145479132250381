import axios from 'axios'
import config from './config';

const axiosInstance = axios.create({
  baseURL: config.baseURL
  // other configurations
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
        window.location.replace(config.mainURL)
      // Handle 401 error, e.g., redirect to login or refresh token
    }
    return Promise.reject(error)
  },
)

export default axiosInstance