import React from "react";
import App from "./../app.jsx";

import Dashboard from "./../pages/dashboard/dashboard.js";

import PagesError from "./../pages/pages/error.js";

import MpDetail from "../pages/manpower/profile.jsx";

import Logingin from "../pages/logingin/login.js";

import AppLogin from "../appLogin.jsx";
import Kpi from "../pages/kpi/kpi.jsx";
import Data from "../pages/manpower/data.jsx";
import NewManpower from "../pages/manpower/new.jsx";
import EditManpower from "../pages/manpower/edit.jsx";
import DataTraining from "../pages/training/data.jsx";
import DetailTraining from "../pages/training/detail.jsx";
import TrainingReport from "../pages/training/report.jsx";
import ReportEvent from "../pages/training/reportEvent.jsx";
import ReportParticipant from "../pages/training/reportParticipant.jsx";
import ReportDocument from "../pages/training/reportDocument.jsx";
import DataMentoring from "../pages/mentoring/data.jsx";
import DetailMentoring from "../pages/mentoring/detail.jsx";
import MentoringReport from "../pages/mentoring/report.jsx";
import MentorReportEvent from "../pages/mentoring/reportEvent.jsx";
import MentorReportDocument from "../pages/mentoring/reportDocument.jsx";
import TrainingPlanData from "../pages/trainingPlan/data.jsx";
import PlanTrainingDetail from "../pages/trainingPlan/detail.jsx";
import PlanTrainingNewReport from "../pages/trainingPlan/report.jsx";
import QuestionData from "../pages/question/data.jsx";
import QuestionDetail from "../pages/question/detail.jsx";

const AppRoute = [
  {
    path: "*",
    element: <AppLogin />,
    children: [
      // { path: '', element: <Navigate to='/dashboard' /> },
      { path: "ss/:blank", element: <Logingin /> },
    ],
  },
  {
    path: "*",
    element: <App />,
    children: [
      // { path: '', element: <Navigate to='/dashboard' /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "kpi", element: <Kpi /> },
      {
        path: "manpower/*",
        children: [
          { path: "data", element: <Data /> },
          { path: "detail/:id", element: <MpDetail /> },
          { path: "new", element: <NewManpower /> },
          { path: "edit/:id", element: <EditManpower /> },
        ],
      },
      {
        path: "training/*",
        children: [
          { path: "data", element: <DataTraining /> },
          { path: "detail/:id", element: <DetailTraining /> },
          { path: "plan/data", element: <TrainingPlanData /> },
          { path: "plan/detail/:id", element: <PlanTrainingDetail /> },
          { path: "plan/new", element: <PlanTrainingNewReport /> },
          {
            path: "report/",
            element: <TrainingReport />,
            children: [
              { path: "event", element: <ReportEvent /> },
              { path: "participant", element: <ReportParticipant /> },
              { path: "document", element: <ReportDocument /> },
            ],
          },
        ],
      },
      {
        path: "mentoring/*",
        children: [
          { path: "data", element: <DataMentoring /> },
          { path: "detail/:id", element: <DetailMentoring /> },
          {
            path: "report/",
            element: <MentoringReport />,
            children: [
              { path: "event", element: <MentorReportEvent /> },
              { path: "document", element: <MentorReportDocument /> },
            ],
          },
        ],
      },
      // {
      //   path: "question/*",
      //   children: [
      //     { path: "data", element: <QuestionData /> },
      //     { path: "detail/:id", element: <QuestionDetail /> },
      //   ],
      // },
      // { path: 'widgets', element: <Widgets /> },
      // { path: 'pos/*',
      // 	children: [
      // 			{ path: 'customer-order', element: <PosCustomerOrder /> },
      // 			{ path: 'kitchen-order', element: <PosKitchenOrder /> },
      // 			{ path: 'counter-checkout', element: <PosCounterCheckout /> },
      // 			{ path: 'table-booking', element: <PosTableBooking /> },
      // 			{ path: 'menu-stock', element: <PosMenuStock /> }
      // 		]
      // 	},
      // { path: 'ui/*',
      // 	children: [
      // 			{ path: 'bootstrap', element: <UiBootstrap /> },
      // 			{ path: 'buttons', element: <UiButtons /> },
      // 			{ path: 'card', element: <UiCard /> },
      // 			{ path: 'icons', element: <UiIcons /> },
      // 			{ path: 'modal-notifications', element: <UiModalNotifications /> },
      // 			{ path: 'typography', element: <UiTypography /> },
      // 			{ path: 'tabs-accordions', element: <UiTabsAccordions /> },
      // 		]
      // 	},
      // { path: 'form/*',
      // 	children: [
      // 			{ path: 'elements', element: <FormElements /> },
      // 			{ path: 'plugins', element: <FormPlugins /> },
      // 			{ path: 'wizards', element: <FormWizards /> }
      // 		]
      // 	},
      // { path: 'table/*',
      // 	children: [
      // 			{ path: 'elements', element: <TableElements /> },
      // 			{ path: 'plugins', element: <TablePlugins /> }
      // 		]
      // 	},
      // { path: 'chart/*',
      // 	children: [
      // 			{ path: 'chart-js', element: <ChartJs /> },
      // 			{ path: 'chart-apex', element: <ChartApex /> }
      // 		]
      // 	},
      // { path: 'layout/*',
      // 	children: [
      // 			{ path: 'starter-page', element: <LayoutStarter /> },
      // 			{ path: 'fixed-footer', element: <LayoutFixedFooter /> },
      // 			{ path: 'full-height', element: <LayoutFullHeight /> },
      // 			{ path: 'full-width', element: <LayoutFullWidth /> },
      // 			{ path: 'boxed-layout', element: <LayoutBoxedLayout /> },
      // 			{ path: 'collapsed-sidebar', element: <LayoutCollapsedSidebar /> },
      // 			{ path: 'top-nav', element: <LayoutTopNav /> },
      // 			{ path: 'mixed-nav', element: <LayoutMixedNav /> },
      // 			{ path: 'mixed-nav-boxed-layout', element: <LayoutMixedNavBoxedLayout /> }
      // 		]
      // 	},
      // { path: 'pages/*',
      // 	children: [
      // 			{ path: 'scrum-board', element: <PagesScrumBoard /> },
      // 			{ path: 'products', element: <PagesProducts /> },
      // 			{ path: 'product-details', element: <PagesProductDetails /> },
      // 			{ path: 'orders', element: <PagesOrders /> },
      // 			{ path: 'order-details', element: <PagesOrderDetails /> },
      // 			{ path: 'gallery', element: <PagesGallery /> },
      // 			{ path: 'search-results', element: <PagesSearchResults /> },
      // 			{ path: 'coming-soon', element: <PagesComingSoon /> },
      // 			{ path: 'error', element: <PagesError /> },
      // 			{ path: 'login', element: <PagesLogin /> },
      // 			{ path: 'register', element: <PagesRegister /> },
      // 			{ path: 'messenger', element: <PagesMessenger /> },
      // 			{ path: 'data-management', element: <PagesDataManagement /> },
      // 			{ path: 'file-manager', element: <PagesFileManager /> },
      // 			{ path: 'pricing', element: <PagesPricing /> }
      // 		]
      // 	},
      // { path: 'map', element: <Map /> },
      // { path: 'profile', element: <Profile /> },
      // { path: 'calendar', element: <Calendar /> },
      // { path: 'settings', element: <Settings /> },
      // { path: 'helper', element: <Helper /> },
      // { path: '*', element: <PagesError /> }
    ],
  },
  {
    path: "*",
    element: <AppLogin />,
    children: [{ path: "*", element: <PagesError /> }],
  },
];

export default AppRoute;
