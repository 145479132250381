import { useReducer, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../config/axios.js";

function reducer(state, action) {
  switch (action.type) {
    case "participant": {
      return { ...state, participant: action.value };
    }
    case "preTest": {
      return { ...state, preTest: action.value };
    }
    case "postTest": {
      return { ...state, postTest: action.value };
    }
    case "practice": {
      return { ...state, practice: action.value };
    }
    case "result": {
      return { ...state, result: action.value };
    }
    case "remark": {
      return { ...state, remark: action.value };
    }
    case "trainingId": {
      return { ...state, trainingId: action.value };
    }
    case "id": {
      return { ...state, id: action.value };
    }
  }
}
const initialValues = {
  id: "",
  trainingId: "",
  participant: "",
  preTest: "",
  postTest: "",
  practice: "",
  result: "",
  remark: "",
};

function ModalEditParticipantTraining({ show, onHide, submitClick, data }) {
  const [values, valuesDispatch] = useReducer(reducer, initialValues);
  const [option, setOption] = useState([]);
  const resultOption = [
    { value: "Pass", label: "Pass" },
    { value: "Fail", label: "Fail" },
  ];

  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }
  const getOption = () => {
    valuesDispatch({ type: "participant", value: data.idMp });
    valuesDispatch({ type: "preTest", value: data.pre });
    valuesDispatch({ type: "postTest", value: data.post });
    valuesDispatch({ type: "practice", value: data.practice });
    valuesDispatch({ type: "result", value: data.result });
    valuesDispatch({ type: "remark", value: data.remark });
    valuesDispatch({ type: "id", value: data.trainingPesertaId });
    valuesDispatch({ type: "trainingId", value: data.trainingId });
    axiosInstance
      .get("/optionselector/manpower", getHeader())
      .then((res) => setOption(res.data))
      .catch((e) => console.log(e));
  };
  const handleSubmit = () => {
    submitClick(values);
  };
  return (
    <>
      <Modal size="lg" backdrop={"static"} show={show} onShow={() => getOption()} onHide={() => onHide()}>
        <ModalHeader closeButton>
          <h5 className="modal-title">Edit Participant Training</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="participant">
              Participant
            </label>

            <Select
              defaultValue={{ value: data.idMp, label: `${data.perusahaanId == 1 ? data.nrpMp : "NON SIS"} || ${data.nama} || ${data.perusahaan} || ${data.jabatanFn}` }}
              id="participant"
              onChange={(e) => valuesDispatch({ type: "participant", value: e.value })}
              options={option}
              isSearchable
              classNamePrefix="react-select"
            />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="preTest">
              Pre Test
            </label>
            <input className="form-control" defaultValue={data.pre ? data.pre : ""} onChange={(e) => valuesDispatch({ type: "preTest", value: e.target.value })} type="number" id="preTest" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="postTest">
              Post Test
            </label>
            <input className="form-control" defaultValue={data.post ? data.post : ""} onChange={(e) => valuesDispatch({ type: "postTest", value: e.target.value })} type="number" id="postTest" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="practice">
              Practice
            </label>
            <input className="form-control" defaultValue={data.practice ? data.practice : ""} onChange={(e) => valuesDispatch({ type: "practice", value: e.target.value })} type="number" id="practice" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="result">
              Result
            </label>
            <Select defaultValue={{ value: data.result, label: data.result }} id="result" onChange={(e) => valuesDispatch({ type: "result", value: e.value })} options={resultOption} classNamePrefix="react-select" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="remark">
              Remark
            </label>
            <textarea className="form-control" defaultValue={data.remark ? data.remark : ""} onChange={(e) => valuesDispatch({ type: "remark", value: e.target.value })} id="reamrk"></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit()} className="btn btn-outline-theme">
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditParticipantTraining;
