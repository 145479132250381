import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";
import ModalInfo from "../../components/modal/modalInfo.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalEditSelect from "../../components/modal/modalEditSelect.jsx";
import ModalEditDate from "../../components/modal/modalEditDate.jsx";
import ModalEditText from "../../components/modal/modalEditText.jsx";

import ModalEditTextV2 from "../../components/modal/modalEditTextV2.jsx";
import ModalPlanTrainingParticipant from "../../components/modal/modalPlanTrainingParticipant.jsx";
import ModalGenerateTaf from "../../components/modal/modalGenerateTaf.jsx";
import GenerateTaf from "../../components/pdfGenerator/generateTaf.js";

const generalDataPreset = {
  data: [],
};
const pesertaDataPreset = {
  data: [],
};
function PlanTrainingDetail() {
  const param = useParams();
  const [generalData, setGeneralData] = useState(generalDataPreset);
  const [pesertas, setPesertas] = useState(pesertaDataPreset);
  const [remakModal, setRemarkModal] = useState({ show: false, title: "Remark", text: "Please insert reason text" });
  const [remakModalOk, setRemarkModalOk] = useState({ show: false, title: "Remark", text: "Please insert reason text" });
  const [modalConfirmEventDelete, setModalConfirmEventDelete] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalInfo, setModalInfo] = useState({ show: false, message: "no Message", title: "no title" });
  const [modalEditMain, setModalEditMain] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainDate, setModalEditMainDate] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainText, setModalEditMainText] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalAddPart, setModalAddPart] = useState({ show: false, id: null, title: "Add Participant", text: "Please add participant in this training plan. suggest: see ATMP before define participant" });
  const [duplicateEventModal, setDuplicateEventModal] = useState(false);
  const [modalTaf, setModalTaf] = useState({ show: false, id: null });
  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  // ---------------------------------------------------------Generate-------------------------------------------------------------------
  function exportHandleClick(mode) {
    switch (mode) {
      case "taf":
        setModalTaf({ show: true, id: param.id });
        break;

      default:
        break;
    }
  }

  function handleGenerateTafClick(val) {
    GenerateTaf(val);
  }
  // ---------------------------------------------------------End Generate-------------------------------------------------------------------

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  function editMain(e) {
    switch (e.modal) {
      case "select":
        setModalEditMain({ ...modalEditMain, show: true, data: { title: "Edit " + e.title, text: "Please select to edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "date":
        setModalEditMainDate({ ...modalEditMainDate, show: true, data: { title: "Edit " + e.title, text: "Please edit training " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "text":
        setModalEditMainText({ ...modalEditMainText, show: true, data: { title: "Edit " + e.title, text: "Please edit training " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      default:
        break;
    }
  }

  // ---------------------------------------------------------Edit Main--------------------------------------------------------------
  function submitEdit(val) {
    setModalEditMain({ ...modalEditMain, show: false });
    setModalEditMainDate({ ...modalEditMainDate, show: false });
    setModalEditMainText({ ...modalEditMainText, show: false });

    axiosInstance
      .put("plan-training-edit/main", val, getHeader())
      .then((res) => {
        if (res.data.data.message == "duplicate") {
          setModalInfo({ show: true, text: "This training plan already exist......!", title: "Failed" });
          return;
        }
        setGeneralData(res.data);
      })
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Edit Main--------------------------------------------------------------

  // ---------------------------------------------------------Add Participant--------------------------------------------------------------
  function handleAddParticipant() {
    setModalAddPart({ show: true, id: param.id, title: "Add Participant", text: "Please add participant in this training plan. suggest: see ATMP before define participant" });
  }
  function handleSubmitAddParticipant(val) {
    setModalAddPart({ ...modalAddPart, show: false });
    axiosInstance
      .post("plan-training-new/peserta/" + param.id, val, getHeader())
      .then((res) => {
        if (res.data.data.message == "duplicate") {
          setModalInfo({ show: true, text: "This participant is already exist...!", title: "Failed" });
          return;
        }
        setPesertas(res.data);
      })
      .catch((e) => console.log("need relogin"));
  }

  // ---------------------------------------------------------End Add Participant--------------------------------------------------------------

  // ---------------------------------------------------------Delete Event--------------------------------------------------------------

  function handleDeleteEvent() {
    setModalConfirmEventDelete({ show: true, title: "Warning", text: "Are you sure delete this event ?", id: null });
  }

  function handleDeleteEventConfirmed() {
    setModalConfirmEventDelete({ show: false, title: "Warning", text: "Are you sure delete this event ?", id: null });
    axiosInstance
      .delete("plan-training-delete/plan-one/" + param.id, getHeader())
      .then((res) => (res.data.data.message == "success" ? navigate("/training/plan/data") : null))
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Delete Event--------------------------------------------------------------

  // ---------------------------------------------------------Edit Peserta Cancle--------------------------------------------------------------
  function handleSwtichClickCancle(id) {
    setRemarkModal({ ...remakModal, id: id, show: true });
  }

  function handleSubmitEditPesertaToCancle(val) {
    setRemarkModal({ ...remakModal, show: false });
    axiosInstance
      .put("/plan-training-edit/peserta-to-cancle/" + param.id, val, getHeader())
      .then((res) => setPesertas(res.data))
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Edit Peserta Cancle--------------------------------------------------------------

  function handleOnHideModalEditPeserta() {
    setRemarkModalOk({ ...remakModalOk, show: false });
    setRemarkModal({ ...remakModal, show: false });
    axiosInstance
      .get("/plan-training/peserta/" + param.id, getHeader())
      .then((res) => setPesertas(res.data))
      .catch((e) => console.log("need relogin"));
  }

  // ---------------------------------------------------------Edit Peserta OK--------------------------------------------------------------
  function handleSwtichClickOk(id) {
    setRemarkModalOk({ ...remakModalOk, id: id, show: true });
  }

  function handleSubmitEditPesertaToOk(val) {
    setRemarkModalOk({ ...remakModalOk, show: false });
    axiosInstance
      .put("/plan-training-edit/peserta-to-ok/" + param.id, val, getHeader())
      .then((res) => setPesertas(res.data))
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Edit Peserta OK--------------------------------------------------------------

  useEffect(() => {
    axiosInstance
      .get("/plan-training/main-one/" + param.id, getHeader())
      .then((res) => setGeneralData(res.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/plan-training/peserta/" + param.id, getHeader())
      // .then((res) => console.log(res))
      .then((res) => setPesertas(res.data))
      .catch((e) => console.log("need relogin"));
  }, []);

  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">TRAINING</a>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ul>

        <h1 className="page-header">
          Training Plan <small></small>
        </h1>

        <hr className="mb-4" />

        <div className="mb-md-4 mb-3 d-md-flex">
          <div className="mt-md-0 mt-2 dropdown-toggle">
            <a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-75 text-decoration-none">
              <i className="fa fa-download fa-fw me-1 text-theme"></i>
              Generate
            </a>
            <div className="dropdown-menu">
              <a onClick={() => exportHandleClick("taf")} className="dropdown-item" href="#/">
                Training Application Form (TAF)
              </a>
            </div>
          </div>
        </div>

        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">General</CardHeader>
              <CardBody>
                {generalData.data.length > 0 ? (
                  generalData.data.map((value, index) => (
                    <div className="list-group list-group-flush" key={index}>
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div className="text-theme">{value.title}</div>
                          <div className="text-inverse text-opacity-50">{value.value}</div>
                        </div>

                        {value.editBtn ? (
                          <div className="w-50px text-center">
                            <a href="#/" onClick={() => editMain(value)} className="btn btn-outline-default btn-sm">
                              <i className="fas fa-edit text-theme"></i>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Participant</CardHeader>
              <CardBody>
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleAddParticipant()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Add Part..</span>
                    </span>
                  </div>
                </div>

                {pesertas.data.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th className="border-top-0 pt-0 pb-2">No.</th>
                          <th className="border-top-0 pt-0 pb-2">Name</th>
                          <th className="border-top-0 pt-0 pb-2">Position</th>
                          <th className="border-top-0 pt-0 pb-2">Status</th>
                          <th className="border-top-0 pt-0 pb-2">Remark</th>
                          <th className="border-top-0 pt-0 pb-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pesertas.data.map((peserta, index) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">
                              <Link to={"/manpower/detail/" + peserta.idMp}>{peserta.peserta ? peserta.peserta.nama : "Blank"}</Link>
                            </td>
                            <td className="align-middle">{peserta.peserta ? peserta.peserta.jabatanFn : "Blank"}</td>
                            <td className="align-middle">
                              {peserta.status != "OK" ? (
                                <div className="form-check form-switch">
                                  <input type="checkbox" onChange={() => handleSwtichClickOk(peserta.id)} className="form-check-input" checked={false} />
                                  <label className="form-check-label text-danger">{peserta.status}</label>
                                </div>
                              ) : (
                                <div className="form-check form-switch">
                                  <input type="checkbox" onChange={() => handleSwtichClickCancle(peserta.id)} className="form-check-input" checked={true} />
                                  <label className="form-check-label">{peserta.status}</label>
                                </div>
                              )}
                            </td>
                            <td className="align-middle">{peserta.remark}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>no Data</p>
                )}
              </CardBody>
            </Card>

            <a onClick={() => handleDeleteEvent()} href="#/" className="text-danger">
              Delete
            </a>
          </div>
        </div>
      </div>
      <ModalInfo show={modalInfo.show} clicked={() => setModalInfo({ ...modalInfo, show: false })} onHide={() => setModalInfo({ ...modalInfo, show: false })} theme={"danger"} title={modalInfo.title} text={modalInfo.text} />
      <ModalConfirm
        show={modalConfirmEventDelete.show}
        clicked={() => handleDeleteEventConfirmed()}
        onHide={() => setModalConfirmEventDelete({ ...modalConfirmEventDelete, show: false })}
        title={modalConfirmEventDelete.title}
        text={modalConfirmEventDelete.text}
        theme={"danger"}
      />
      <ModalEditSelect show={modalEditMain.show} data={modalEditMain.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMain({ ...modalEditMain, show: false })} />
      <ModalEditDate show={modalEditMainDate.show} data={modalEditMainDate.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainDate({ ...modalEditMainDate, show: false })} />
      <ModalEditText show={modalEditMainText.show} data={modalEditMainText.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainText({ ...modalEditMainText, show: false })} />

      <ModalPlanTrainingParticipant
        title={modalAddPart.title}
        id={modalAddPart.id}
        text={modalAddPart.text}
        submitClick={(val) => handleSubmitAddParticipant(val)}
        show={modalAddPart.show}
        onHide={() => setModalAddPart({ ...modalAddPart, show: false })}
      />
      <ModalConfirm show={duplicateEventModal} clicked={() => setDuplicateEventModal(false)} onHide={() => setDuplicateEventModal(false)} title={"Warning"} text={"This event already exist"} theme={"danger"} />
      <ModalEditTextV2 show={remakModal.show} id={remakModal.id} submitClick={(val) => handleSubmitEditPesertaToCancle(val)} onHide={() => handleOnHideModalEditPeserta()} title={remakModal.title} text={remakModal.text} />
      <ModalEditTextV2 show={remakModalOk.show} id={remakModalOk.id} submitClick={(val) => handleSubmitEditPesertaToOk(val)} onHide={() => handleOnHideModalEditPeserta()} title={remakModalOk.title} text={remakModalOk.text} />
      <ModalGenerateTaf show={modalTaf.show} id={modalTaf.id} submitClick={(e) => handleGenerateTafClick(e)} onHide={() => setModalTaf({ ...modalTaf, show: false })} />
    </>
  );
}

export default PlanTrainingDetail;
