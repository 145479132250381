import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../config/axios.js";
const parameter = { id: "", val: "", edit: "" };

function ModalEditSelect({ show, onHide, submitClick, data = {}, headerFetch }) {
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }
  const attr = {
    title: data.hasOwnProperty("title") ? data.title : "Edit Value",
    text: data.hasOwnProperty("text") ? data.text : "Please edit as you wish :)",
    data: data.hasOwnProperty("data") ? data.data : { id: "", val: "", edit: "" },
  };
  const [val, setVal] = useState(attr.data);
  const [option, setOption] = useState([]);
  const getOption = () => {
    axiosInstance
      .get("/optionselector/" + attr.data.edit, getHeader())
      .then((res) => setOption(res.data))
      .catch((e) => console.log(e));
  };
  const handleChange = (e) => {
    setVal({ id: attr.data.id, val: e.value, edit: attr.data.edit });
  };
  return (
    <>
      <Modal
        show={show}
        onShow={() => getOption()}
        onHide={() => {
          setVal(parameter);
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{attr.title}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Select onChange={(e) => handleChange(e)} options={option} isSearchable classNamePrefix="react-select" />
          </div>
          <div className="alert bg-inverse bg-opacity-15">{attr.text}</div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer">
            <button type="button" onClick={() => submitClick(val)} className={val.val ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
              Save changes
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalEditSelect;
