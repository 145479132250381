import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";
import ModalUploadFile from "../../components/modal/modalUploadFile.jsx";
import ModalLoading from "../../components/modal/modalLoading.jsx";
import ModalInfo from "../../components/modal/modalInfo.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalEditSelect from "../../components/modal/modalEditSelect.jsx";
import ModalEditDate from "../../components/modal/modalEditDate.jsx";
import ModalEditText from "../../components/modal/modalEditText.jsx";
import ModalEditTime from "../../components/modal/modalEditTime.jsx";

function DetailMentoring() {
  const param = useParams();
  const [generalData, setGeneralData] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [modalLoading, setModalLoading] = useState({ show: false, value: 0 });
  const [modalUploadFile, setModalUploadFile] = useState({ show: false, title: "Choose Document", text: "Document like absensi, worksheet etc.", data: { id: null, val: null } });
  const [modalConfirm, setModalConfirm] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalConfirmEventDelete, setModalConfirmEventDelete] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalInfo, setModalInfo] = useState({ show: false, message: "no Message", title: "no title" });
  const [modalEditMain, setModalEditMain] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainDate, setModalEditMainDate] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainTime, setModalEditMainTime] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainText, setModalEditMainText] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [loadingValue, setLoadingValue] = useState(0);

  const [duplicateEventModal, setDuplicateEventModal] = useState(false);

  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  function handleUploadFile() {
    setModalUploadFile({ show: true, title: "Choose Document", text: "Document like absensi, worksheet, TAF etc.", data: { id: param.id, val: null } });
  }

  function handleSubmitFile(file) {
    setModalUploadFile({ ...modalUploadFile, show: false });
    setModalLoading({ ...modalLoading, title: "Uploading", show: true, text: "Please wait uploading in proggress..." });
    axiosInstance
      .post("/mentoring-uploadDocument", file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onUploadProgress: (pv) => {
          setLoadingValue(Math.round((pv.loaded / pv.total) * 100));
        },
        validateStatus: false,
      })
      .then((res) => {
        setModalLoading({ ...modalLoading, show: false });
        if (res.data.status) {
          setDocuments(res.data.data);
          // console.log(res.data.data);
        } else {
          setModalInfo({ ...modalInfo, show: true, title: "Warning", text: res.data.message.fileName[0] });
        }
      });
  }
  function handleDownload(e, fileName) {
    setModalLoading({ ...modalLoading, title: "Downloading", text: "Please wait downloading in proggress..", show: true });
    axiosInstance
      .get("mentoring-document/" + e, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
        },
        onDownloadProgress: (e) => {
          setLoadingValue(Math.round((e.loaded / e.total) * 100));
        },
        responseType: "blob",
        validateStatus: false,
      })
      .then((res) => {
        saveFile(res, fileName);
        setModalLoading({ ...modalLoading, show: false });
      })
      .catch((e) => console.log("need relogin"));
  }

  function saveFile(res, fileName) {
    const href = URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  function handleDeleteFile(id, fileName) {
    setModalConfirm({ ...modalConfirm, show: true, text: "Are you sure want to delete: " + fileName + " ?", id: id });
  }

  function handleDeleteConfirmed() {
    axiosInstance
      .delete("/mentoring-deleteDocument/" + modalConfirm.id, getHeader())
      .then((res) => {
        setModalConfirm({ ...modalConfirm, show: false });
        setDocuments(res.data.data);
      })
      .catch((e) => console.log("need relogin"));
  }

  function editMain(e) {
    switch (e.modal) {
      case "select":
        setModalEditMain({ ...modalEditMain, show: true, data: { title: "Edit " + e.title, text: "Please select to edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "date":
        setModalEditMainDate({ ...modalEditMainDate, show: true, data: { title: "Edit " + e.title, text: "Please edit mentoring " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "time":
        setModalEditMainTime({ ...modalEditMainTime, show: true, data: { title: "Edit " + e.title, text: "Please edit mentoring " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "text":
        setModalEditMainText({ ...modalEditMainText, show: true, data: { title: "Edit " + e.title, text: "Please edit mentoring " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      default:
        break;
    }
  }

  function submitEdit(val) {
    setModalEditMain({ ...modalEditMain, show: false });
    setModalEditMainDate({ ...modalEditMainDate, show: false });
    setModalEditMainTime({ ...modalEditMainTime, show: false });
    setModalEditMainText({ ...modalEditMainText, show: false });

    axiosInstance
      .put("/mentoring-edit/main", val, getHeader())
      .then((res) => {
        if (res.data.status) {
          setGeneralData(res.data.data);
        } else {
          setDuplicateEventModal(true);
        }
      })
      .catch((e) => console.log("need relogin"));
  }

  function handleDeleteEvent() {
    setModalConfirmEventDelete({ show: true, title: "Warning", text: "Are you sure delete this mentoring data ?", id: null });
  }

  function handleDeleteEventConfirmed() {
    setModalConfirmEventDelete({ show: false, title: "Warning", text: "Are you sure delete this mentoring data ?", id: null });
    axiosInstance
      .delete("/mentoring-delete/" + param.id, getHeader())
      .then((res) => (res.data.status ? navigate("/mentoring/data") : null))
      .catch((e) => console.log("need relogin"));
  }

  useEffect(() => {
    axiosInstance
      .get("/mentoring/single/" + param.id, getHeader())
      .then((res) => setGeneralData(res.data.data))
      .catch((e) => console.log("need relogin"));
    axiosInstance
      .get("/mentoring/document/" + param.id, getHeader())
      .then((res) => setDocuments(res.data.data))
      .catch((e) => console.log("need relogin"));
  }, []);

  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">MENTORING</a>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ul>

        <h1 className="page-header">
          Mentoring Records <small></small>
        </h1>

        <hr className="mb-4" />

        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Mentoring Data</CardHeader>
              <CardBody>
                {generalData.length > 0 ? (
                  generalData.map((value, index) => (
                    <div className="list-group list-group-flush" key={index}>
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div className="text-theme">{value.title}</div>
                          <div className="text-inverse text-opacity-50">{value.value}</div>
                        </div>

                        {value.editBtn ? (
                          <div className="w-50px text-center">
                            <a href="#/" onClick={() => editMain(value)} className="btn btn-outline-default btn-sm">
                              <i className="fas fa-edit text-theme"></i>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Document</CardHeader>
              <CardBody className="card-body pb-2">
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleUploadFile()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Add files...</span>
                    </span>
                  </div>
                </div>
              </CardBody>
              <table className="table table-card mb-0 fs-13px">
                <thead>
                  <tr className="fs-12px">
                    <th className="pt-2 pb-2 w-25">FILENAME</th>
                    <th className="pt-2 pb-2 w-25">SIZE</th>
                    <th className="pt-2 pb-2 w-25">UPLOADED</th>
                    <th className="pt-2 pb-2 w-25">ACTION</th>
                  </tr>
                </thead>
                <tbody className="files">
                  {documents.length > 0 ? (
                    documents.map((document, index) => (
                      <tr key={index}>
                        <td className="align-middle">{document.fileName}</td>
                        <td className="align-middle">{document.size}</td>
                        <td className="align-middle">{dateFormat(document.updated_at)}</td>
                        <td>
                          <a href="#/" onClick={() => handleDeleteFile(document.id, document.fileName)} className="btn btn-outline-default btn-sm">
                            <i className="fas fa-trash-alt text-danger"></i>
                          </a>
                          <a href="#/" onClick={() => handleDownload(document.id, document.fileName)} className="btn btn-outline-default btn-sm">
                            <i className="fas fa-download text-theme"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="empty-row">
                      <td colSpan="4" className="text-center p-3">
                        <div className="text-gray-300 mb-2">
                          <i className="fa fa-file-archive fa-3x"></i>
                        </div>
                        No file uploaded
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
            <a onClick={() => handleDeleteEvent()} href="#/" className="text-danger">
              Delete
            </a>
          </div>
        </div>
      </div>
      <ModalUploadFile show={modalUploadFile.show} submitClick={(e) => handleSubmitFile(e)} onHide={() => setModalUploadFile(false)} data={modalUploadFile} />
      <ModalLoading show={modalLoading.show} title={modalLoading.title} text={modalLoading.text} value={loadingValue} onHide={() => setModalLoading({ ...modalLoading, show: false })} />
      <ModalInfo show={modalInfo.show} clicked={() => setModalInfo({ ...modalInfo, show: false })} onHide={() => setModalInfo({ ...modalInfo, show: false })} theme={"danger"} title={modalInfo.title} text={modalInfo.text} />
      <ModalConfirm show={modalConfirm.show} clicked={() => handleDeleteConfirmed()} onHide={() => setModalConfirm({ ...modalConfirm, show: false })} title={modalConfirm.title} text={modalConfirm.text} theme={"danger"} />
      <ModalConfirm
        show={modalConfirmEventDelete.show}
        clicked={() => handleDeleteEventConfirmed()}
        onHide={() => setModalConfirmEventDelete({ ...modalConfirmEventDelete, show: false })}
        title={modalConfirmEventDelete.title}
        text={modalConfirmEventDelete.text}
        theme={"danger"}
      />
      <ModalEditSelect show={modalEditMain.show} data={modalEditMain.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMain({ ...modalEditMain, show: false })} />
      <ModalEditDate show={modalEditMainDate.show} data={modalEditMainDate.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainDate({ ...modalEditMainDate, show: false })} />
      <ModalEditText show={modalEditMainText.show} data={modalEditMainText.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainText({ ...modalEditMainText, show: false })} />
      <ModalEditTime show={modalEditMainTime.show} data={modalEditMainTime.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainTime({ ...modalEditMainTime, show: false })} />
      <ModalConfirm show={duplicateEventModal} clicked={() => setDuplicateEventModal(false)} onHide={() => setDuplicateEventModal(false)} title={"Warning"} text={"This event already exist"} theme={"danger"} />
    </>
  );
}

export default DetailMentoring;
