import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios.js";
import { CardHeader } from "react-bootstrap";
import ModalInfo from "../../components/modal/modalInfo.jsx";
import ModalConfirm from "../../components/modal/modalConfirm.jsx";
import ModalEditSelect from "../../components/modal/modalEditSelect.jsx";
import ModalEditDate from "../../components/modal/modalEditDate.jsx";
import ModalEditText from "../../components/modal/modalEditText.jsx";
import ModalEditSoalMc from "../../components/modal/modalEditSoalMc.jsx";
import ModalNewSoalMc from "../../components/modal/modalNewSoalMc.jsx";
import ModalEditNumber from "../../components/modal/modalEditNumber.jsx";

const generalDataPreset = {
  data: [],
};

function QuestionDetail() {
  const param = useParams();
  const [generalData, setGeneralData] = useState(generalDataPreset);
  const [modalConfirmEventDelete, setModalConfirmEventDelete] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalConfirmDeleteMc, setModalConfirmDeleteMc] = useState({ show: false, title: "Warning", text: "no text", id: null });
  const [modalInfo, setModalInfo] = useState({ show: false, message: "no Message", title: "no title" });
  const [modalEditMain, setModalEditMain] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainDate, setModalEditMainDate] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainText, setModalEditMainText] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [modalEditMainNumber, setModalEditMainNumber] = useState({ show: false, title: "Please Select", text: "blank", data: {}, headerFetch: {} });
  const [duplicateEventModal, setDuplicateEventModal] = useState(false);
  const [modalSoalMc, setModalSoalMc] = useState({ show: false, id: null });
  const [modalNewSoalMc, setModalNewSoalMc] = useState({ show: false, id: null });
  const [soalMc, setSoalMc] = useState([]);
  const [soalTf, setSoalTf] = useState([]);
  const navigate = useNavigate();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  // ---------------------------------------------------------Generate-------------------------------------------------------------------

  // ---------------------------------------------------------End Generate-------------------------------------------------------------------

  function dateFormat(d) {
    const date = new Date(d);
    return date.toDateString();
  }

  function editMain(e) {
    switch (e.modal) {
      case "select":
        setModalEditMain({ ...modalEditMain, show: true, data: { title: "Edit " + e.title, text: "Please select to edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "date":
        setModalEditMainDate({ ...modalEditMainDate, show: true, data: { title: "Edit " + e.title, text: "Please edit training " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "text":
        setModalEditMainText({ ...modalEditMainText, show: true, data: { title: "Edit " + e.title, text: "Please edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
      case "number":
        setModalEditMainText({ ...modalEditMainNumber, show: true, data: { title: "Edit " + e.title, text: "Please edit " + e.title, data: { id: param.id, val: e.value, edit: e.key } } });
        break;
    }
  }

  // ---------------------------------------------------------Edit Main--------------------------------------------------------------
  function submitEdit(val) {
    setModalEditMain({ ...modalEditMain, show: false });
    setModalEditMainDate({ ...modalEditMainDate, show: false });
    setModalEditMainText({ ...modalEditMainText, show: false });

    axiosInstance
      .put("question-edit/soal-main", val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Edit Main--------------------------------------------------------------

  // ---------------------------------------------------------Add Participant--------------------------------------------------------------
  function handleAddMcQuestion() {
    setModalNewSoalMc({ show: true, id: null });
  }

  // ---------------------------------------------------------End Add Participant--------------------------------------------------------------

  // ---------------------------------------------------------Delete Question--------------------------------------------------------------
  function handleDeleteQuestion() {
    setModalConfirmEventDelete({ show: true, title: "Warning", text: "Are you sure delete this question ?", id: null });
  }

  function handleDeleteQuestionConfirmed() {
    setModalConfirmEventDelete({ show: false, title: "Warning", text: "Are you sure delete this question ?", id: null });
  }
  // ---------------------------------------------------------End Delete Question--------------------------------------------------------------

  // ---------------------------------------------------------Edit Multiplel Choice-------------------------------------------------------------------
  function handleClickEditsoalMc(id) {
    setModalSoalMc({ show: true, id: id });
  }
  function handleEditMcSubmitClick(val) {
    axiosInstance
      .put("/question-edit/multiple-choice/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setModalSoalMc({ show: false, id: null });
      })
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End Edit Multiplel Choice----------------------------------------------------------------
  // ---------------------------------------------------------Delete Multiplel Choice----------------------------------------------------------------
  function handleClickDeletesoalMc(id) {
    setModalConfirmDeleteMc({ show: true, title: "Warning", text: "Are you sure want to delete this question ??", id: id });
  }

  function handleDeleteMcConfirmed(id) {
    setModalConfirmDeleteMc({ ...modalConfirmDeleteMc, show: false });
    axiosInstance
      .delete("/question-delete/mc/" + param.id + "/" + id, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => console.log("need relogin"));
    // ---------------------------------------------------------New Multiplel Choice----------------------------------------------------------------
  }
  // ---------------------------------------------------------End Delete Multiplel Choice----------------------------------------------------------------

  function handleNewMcSubmitClick(val) {
    setModalNewSoalMc({ show: false, id: null });
    axiosInstance
      .post("/question-new/new-mc/" + param.id, val, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => console.log("need relogin"));
  }
  // ---------------------------------------------------------End New Multiplel Choice----------------------------------------------------------------

  function handleSoalStatusClick(val, id) {
    axiosInstance
      .put("/question-edit/soal-mc-status/" + param.id, { val: val, id: id }, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
      })
      .catch((e) => console.log("need relogin"));
  }
  useEffect(() => {
    axiosInstance
      .get("/question-get/one-set/" + param.id, getHeader())
      .then((res) => {
        setGeneralData(res.data.data.main);
        setSoalMc(res.data.data.soalMc);
        setSoalTf(res.data.data.soalTf);
        console.log(res.data.data.soalTf);
      })
      .catch((e) => console.log("need relogin"));
  }, []);

  return (
    <>
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">TEST</a>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ul>

        <h1 className="page-header">
          Question Detail <small></small>
        </h1>

        <hr className="mb-4" />

        <div className="row gx-4">
          <div className="col-lg-8">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">General</CardHeader>
              <CardBody>
                {generalData.length > 0 ? (
                  generalData.map((value, index) => (
                    <div className="list-group list-group-flush" key={index}>
                      <div className="list-group-item d-flex align-items-center">
                        <div className="flex-1 text-break">
                          <div className="text-theme">{value.title}</div>
                          <div className="text-inverse text-opacity-50">{value.value}</div>
                        </div>

                        {value.editBtn ? (
                          <div className="w-50px text-center">
                            <a href="#/" onClick={() => editMain(value)} className="btn btn-outline-default btn-sm">
                              <i className="fas fa-edit text-theme"></i>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-400 bg-inverse bg-opacity-10">
                <div className="flex-1">
                  <div>Resume</div>
                </div>
              </CardHeader>
              <CardBody>------------</CardBody>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card className="mb-4">
              <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">Multiple Choice</CardHeader>
              <CardBody>
                <div className="mb-2">
                  <div className="d-block d-lg-flex align-items-center">
                    <span onClick={() => handleAddMcQuestion()} className="btn btn-outline-theme me-2 mb-1">
                      <i className="fa fa-fw fa-plus"></i>
                      <span>Add</span>
                    </span>
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------- */}
                {soalMc.length > 0 ? (
                  soalMc.map((soal, index) => (
                    <div key={index}>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item d-flex px-3">
                          <div className="me-3">
                            <div className="fw-400">{index + 1 + "."}</div>
                          </div>
                          {/* ----------------------------------------------------------------------------------------------------- */}
                          <div className="flex-fill">
                            <div className="fw-400 mb-1">{soal.pertanyaan}</div>

                            {soal.imagePath ? (
                              <div className="mb-3">
                                <img className="img-thumbnail" src={soal.img} alt="No Image" />
                              </div>
                            ) : (
                              ""
                            )}

                            {soal.jawaban.length > 0 ? (
                              soal.jawaban.map((jawaban, ind) => (
                                <div key={ind} className="form-group small">
                                  <div className="form-check">
                                    {jawaban.trueChoice ? (
                                      <>
                                        <input type="radio" className="form-check-input" disabled defaultChecked />
                                        <label className="form-label text-theme" htmlFor="customCheck1">
                                          {jawaban.choices}
                                        </label>
                                      </>
                                    ) : (
                                      <>
                                        <input type="radio" className="form-check-input" disabled />
                                        <label className="form-label" htmlFor="customCheck1">
                                          {jawaban.choices}
                                        </label>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>no Choice Answer</p>
                            )}

                            <div className="d-flex align-items-center mt-1   mb-2">
                              <div className="fw-400 me-1">
                                <div className="form-check form-switch">
                                  {soal.status ? (
                                    <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" defaultChecked id="customSwitch1" />
                                  ) : (
                                    <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" id="customSwitch1" />
                                  )}
                                  <a href="#/" className="me-3" onClick={() => handleClickEditsoalMc(soal.id)}>
                                    <i className="far fa-edit"></i>
                                  </a>
                                  <a href="#/" onClick={() => handleClickDeletesoalMc(soal.id)}>
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Pre:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="small text-inverse text-opacity-50">Post:1000 Res.</div>
                              <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                              </div>
                              <div className="fs-12px">66%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  ))
                ) : (
                  <p>no Data</p>
                )}
                {soalMc.length > 1 ? (
                  <div className="mt-3">
                    <div className="d-block d-lg-flex align-items-center">
                      <span onClick={() => handleAddMcQuestion()} className="btn btn-outline-theme me-2 mb-1">
                        <i className="fa fa-fw fa-plus"></i>
                        <span>Add</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* ----------------------------------------------------------------------------------------------------- */}
              </CardBody>
            </Card>
            <div className="col-lg-12">
              <Card className="mb-4">
                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">True or False</CardHeader>
                <CardBody>
                  <div className="mb-2">
                    <div className="d-block d-lg-flex align-items-center">
                      <span onClick={() => handleAddMcQuestion()} className="btn btn-outline-theme me-2 mb-1">
                        <i className="fa fa-fw fa-plus"></i>
                        <span>Add</span>
                      </span>
                    </div>
                  </div>

                  {/* -----------------------------------------------------SOAL TRUE FALSE--------------------------------------------------- */}
                  {soalTf.length > 0 ? (
                    soalTf.map((soal, index) => (
                      <div key={index}>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item d-flex px-3">
                            <div className="me-3">
                              <div className="fw-400">{index + 1 + "."}</div>
                            </div>
                            {/* ----------------------------------------------------------------------------------------------------- */}
                            <div className="flex-fill">
                              <div className="fw-400 mb-1">{soal.pertanyaan}</div>

                              {soal.imagePath ? (
                                <div className="mb-3">
                                  <img className="img-thumbnail" src={soal.img} alt="No Image" />
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="form-group small">
                                <div className="form-check">
                                  {soal.answer ? <input type="radio" className="form-check-input" disabled defaultChecked /> : <input type="radio" className="form-check-input" disabled name="answer" />}
                                  <label className={soal.answer ? "form-label text-theme" : "form-label"} htmlFor="customCheck1">
                                    TRUE
                                  </label>
                                </div>
                                <div className="form-check">
                                  {!soal.answer ? <input type="radio" className="form-check-input" disabled defaultChecked /> : <input type="radio" className="form-check-input" disabled name="answer" />}
                                  <label className={!soal.answer ? "form-label text-theme" : "form-label"} htmlFor="customCheck1">
                                    FALSE
                                  </label>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-1   mb-2">
                                <div className="fw-400 me-1">
                                  <div className="form-check form-switch">
                                    {soal.status ? (
                                      <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" defaultChecked id="customSwitch1" />
                                    ) : (
                                      <input type="checkbox" onChange={(e) => handleSoalStatusClick(e.target.checked, soal.id)} className="form-check-input me-3" id="customSwitch1" />
                                    )}
                                    <a href="#/" className="me-3" onClick={() => handleClickEditsoalMc(soal.id)}>
                                      <i className="far fa-edit"></i>
                                    </a>
                                    <a href="#/" onClick={() => handleClickDeletesoalMc(soal.id)}>
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex align-items-center">
                                <div className="small text-inverse text-opacity-50">Pre:1000 Res.</div>
                                <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                  <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                                </div>
                                <div className="fs-12px">66%</div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="small text-inverse text-opacity-50">Post:1000 Res.</div>
                                <div className="progress progress-xs w-100px me-2 ms-auto" style={{ height: "6px" }}>
                                  <div className="progress-bar progress-bar-striped bg-theme" style={{ width: "66%" }}></div>
                                </div>
                                <div className="fs-12px">66%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-1" />
                      </div>
                    ))
                  ) : (
                    <p>no Data</p>
                  )}
                  {/* -----------------------------------------------------END SOAL TRUE FALSE--------------------------------------------------- */}
                </CardBody>
              </Card>
            </div>

            <a onClick={() => handleDeleteQuestion()} href="#/" className="text-danger">
              Delete
            </a>
          </div>
        </div>
      </div>
      <ModalInfo show={modalInfo.show} clicked={() => setModalInfo({ ...modalInfo, show: false })} onHide={() => setModalInfo({ ...modalInfo, show: false })} theme={"danger"} title={modalInfo.title} text={modalInfo.text} />
      <ModalConfirm
        show={modalConfirmEventDelete.show}
        clicked={() => handleDeleteQuestionConfirmed()}
        onHide={() => setModalConfirmEventDelete({ ...modalConfirmEventDelete, show: false })}
        title={modalConfirmEventDelete.title}
        text={modalConfirmEventDelete.text}
        theme={"danger"}
      />
      <ModalEditSelect show={modalEditMain.show} data={modalEditMain.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMain({ ...modalEditMain, show: false })} />
      <ModalEditDate show={modalEditMainDate.show} data={modalEditMainDate.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainDate({ ...modalEditMainDate, show: false })} />
      <ModalEditText show={modalEditMainText.show} data={modalEditMainText.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainText({ ...modalEditMainText, show: false })} />
      <ModalEditNumber show={modalEditMainNumber.show} data={modalEditMainNumber.data} submitClick={(val) => submitEdit(val)} onHide={() => setModalEditMainNumber({ ...modalEditMainNumber, show: false })} />
      <ModalConfirm
        show={modalConfirmDeleteMc.show}
        clicked={(id) => handleDeleteMcConfirmed(id)}
        onHide={() => setModalConfirmDeleteMc({ ...modalConfirmDeleteMc, show: false })}
        title={modalConfirmDeleteMc.title}
        text={modalConfirmDeleteMc.text}
        id={modalConfirmDeleteMc.id}
        theme={"danger"}
      />
      <ModalConfirm show={duplicateEventModal} clicked={() => setDuplicateEventModal(false)} onHide={() => setDuplicateEventModal(false)} title={"Warning"} text={"This event already exist"} theme={"danger"} />
      <ModalEditSoalMc id={modalSoalMc.id} show={modalSoalMc.show} submitClick={(val) => handleEditMcSubmitClick(val)} onHide={() => setModalSoalMc({ ...modalSoalMc, show: false })} />
      <ModalNewSoalMc id={modalNewSoalMc.id} show={modalNewSoalMc.show} submitClick={(val) => handleNewMcSubmitClick(val)} onHide={() => setModalNewSoalMc({ ...ModalNewSoalMc, show: false })} />
    </>
  );
}

export default QuestionDetail;
