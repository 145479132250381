import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../config/axios.js";

function ModalPlanTrainingParticipant({ show, onHide, submitClick, id, pressetDataSelected, pressetDataRemark, title, text }) {
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }

  const [option, setOption] = useState([]);
  const [selected, setSelected] = useState("");
  const [remark, setRemark] = useState("");

  const getOption = () => {
    axiosInstance
      .get("/optionselector/manpower", getHeader())
      .then((res) => setOption(res.data))
      .catch((e) => console.log(e));
  };

  function handleSubmit() {
    submitClick({ data: { selected: selected, remark: remark }, id: id ? id : null });
  }

  return (
    <>
      <Modal
        show={show}
        onShow={() => getOption()}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <h5 className="modal-title">{title ? title : "Blank Title"}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Select Participant
            </label>
            <Select onChange={(e) => setSelected(e)} options={option} isSearchable classNamePrefix="react-select" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Remark
            </label>
            <textarea onChange={(e) => setRemark(e.target.value)} className="form-control" />
          </div>
          <div className="alert bg-inverse bg-opacity-15">{text ? text : "Blank Text"}</div>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={() => handleSubmit()} className={selected ? "btn btn-outline-theme" : "btn btn-outline-theme disabled"}>
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalPlanTrainingParticipant;
