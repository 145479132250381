import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, CardBody } from "./../../components/card/card.jsx";
import DonatOptions from "../../components/chart/DonatOptions.jsx";
import TrainingChart1 from "../../components/chart/trainingChart1Options.jsx";
import TrainingChart2 from "../../components/chart/trainingChart2Options.jsx";
import Api from "../../config/Api.jsx";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import Select1 from "../../components/select/select1.jsx";
import axiosInstance from "../../config/axios.js";

function Kpi() {
  const [ppdPerformance, setPpdPerformance] = useState(null);
  const [individualPerformance, setIndividualPerformance] = useState(null);
  const [perfomanceChart1Options, setPerformanceChart1Options] = useState(TrainingChart1);
  const [perfomanceChart2Options, setPerformanceChart2Options] = useState(TrainingChart2);
  const [select, setSelect] = useState({ selectedState: false });
  const [instructor, setInstructor] = useState([]);
  const [login] = useOutletContext();
  function getHeader() {
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("nuzulul")}`,
      },
    };
    return headers;
  }
  function setDataKpiAll(data) {
    let d = [];
    let i = data.detail;
    let j = data.resume;
    data.targets.forEach((target) => {
      let opens = [];
      let closes = [];
      let forcast = [];
      let progress = [];
      let resume = "";
      let kpi = "";
      let prog = "0%";
      let donut = {
        open: 100,
        close: 0,
      };

      data.month.forEach((month) => {
        i.forEach((is) => {
          if (target.target === is.targetName && target.subTarget === is.subTargetName && is.bulan === month) {
            opens.push({ x: month, y: is.qtyTarget.open });
            closes.push({ x: month, y: is.actual.bulanan });
            forcast.push({ x: month, y: is.qtyTarget.forcast });
            progress.push({ x: month, y: is.actual.progress });
          }
        });
      });

      j.forEach((js) => {
        if (js.target === target.target && js.subTarget === target.subTarget) {
          kpi = `${js.qty.actual} / ${js.qty.target}`;
          prog = `${js.qty.achievement}%`;
          donut.close = js.qty.currentAchievement > 100 ? 100 : js.qty.currentAchievement;
          donut.open = 100 - donut.close;
          resume = js.updateAt;
        }
      });

      d.push({
        title: `${target.target} ${target.subTarget}`,
        text: `shows the amount of ${target.target} ${target.subTarget} that must be closed per month for 1 year in the current year`,
        barChart: {
          axis: [
            { name: "Close", type: "bar", data: closes },
            { name: "Open", type: "bar", data: opens },
          ],
        },
        lineChart: {
          axis: [
            { name: "Plan", type: "line", data: forcast },
            { name: "Actual", type: "line", data: progress },
          ],
        },
        resume: {
          title: "Year to date",
          donutData: [donut.open, donut.close],
          kpi: kpi,
          progress: prog,
          updated: `Update at ${resume}`,
          // info:infos
        },
      });
    });
    return d;
  }

  function setDataKpiIndividu(data) {
    let f = [];
    let d = [];
    let i = data.kpi.detail;
    let j = data.kpi.resume;
    let m = data.kpi.month;

    data.kpi.targets.forEach((target) => {
      let opens = [];
      let closes = [];
      let forcast = [];
      let progress = [];
      let resume = "";
      let kpi = "";
      let prog = "0%";
      let donut = {
        open: 100,
        close: 0,
      };
      m.forEach((month) => {
        i.forEach((is) => {
          if (target.target === is.targetName && target.subTarget === is.subTargetName && is.bulan === month) {
            opens.push({ x: month, y: is.qtyTarget.open });
            closes.push({ x: month, y: is.actual.bulanan });
            forcast.push({ x: month, y: is.qtyTarget.forcast });
            progress.push({ x: month, y: is.actual.progress });
          }
        });
      });

      j.forEach((js) => {
        if (js.target === target.target && js.subTarget === target.subTarget) {
          kpi = `${js.qty.actual} / ${js.qty.target}`;
          prog = `${js.qty.achievement}%`;
          donut.close = js.qty.currentAchievement > 100 ? 100 : js.qty.currentAchievement;
          donut.open = 100 - donut.close;
          resume = js.updateAt;
        }
      });

      d.push({
        title: `${target.target} ${target.subTarget}`,
        barChart: {
          axis: [
            { name: "Close", type: "bar", data: closes },
            { name: "Open", type: "bar", data: opens },
          ],
        },
        lineChart: {
          axis: [
            { name: "Plan", type: "line", data: forcast },
            { name: "Actual", type: "line", data: progress },
          ],
        },
        resume: {
          title: "Year to date",
          donutData: [donut.open, donut.close],
          kpi: kpi,
          progress: prog,
          updated: `Update at ${resume}`,
          // info:infos
        },
      });
    });

    f.push({
      nama: data.instructorName,
      data: d,
    });

    return f;
  }
  function changeInstructor(e) {
    let id = e.target.value;
    axiosInstance.get(`/kpi/individu/${id}`, getHeader()).then((res) => {
      setIndividualPerformance(setDataKpiIndividu(res.data));
      setSelect({
        selectedState: res.data.selectState,
        options: res.data.insSelect,
      });
    });
  }
  useEffect(() => {
    if (login) {
      axiosInstance.get("/kpi/all", getHeader()).then((res) => {
        setPpdPerformance(setDataKpiAll(res.data));
      });
      axiosInstance.get("/kpi/individu", getHeader()).then((res) => {
        setIndividualPerformance(setDataKpiIndividu(res.data));
        setSelect({
          selectedState: res.data.selectState,
          options: res.data.insSelect,
        });
      });
    }
  }, [login]);

  useEffect(() => {
    document.addEventListener("theme-reload", () => {
      setPerformanceChart1Options(TrainingChart1);
      setPerformanceChart2Options(TrainingChart2);
    });
    axiosInstance.get("/optionselector/instructor-maco", getHeader()).then((res) => setInstructor(res.data));
  }, []);

  return (
    <>
      <h1 className="page-header">
        KPI <small>Key Performance Indicator</small>
      </h1>
      <hr className="mb-4" />
      <p>
        A Key Performance Indicator (KPI), also known as an Indikator Kinerja Utama, is a measurable value that indicates how effectively an organization is achieving its primary business goals. Specifically, KPIs help determine the
        strategic, financial, and operational achievements of a company. They serve as qualitative measurements for performance, assessed within specific time boundaries.
      </p>
      <p>
        In This section displays the general KPI values ​​that have been achieved from the accumulation of{" "}
        <a href="#individualKpi" title="Goto Individual KPI section">
          individual
        </a>{" "}
        KPI values
      </p>

      {/* ------------------------------------KPI All-------------------------------------- */}
      <div className="mb-5">
        <Card>
          <CardBody>
            <div className="row">
              {ppdPerformance
                ? ppdPerformance.map((kpiAll, index) => (
                    <div key={index}>
                      <div className="col-xl-12 mb-3">
                        <h5>{kpiAll.title}</h5>
                        <p>{kpiAll.text}</p>
                        {kpiAll.barChart ? <Chart type="bar" height={300} options={perfomanceChart1Options} series={kpiAll.barChart.axis} /> : "No Data"}
                        {kpiAll.barChart ? <Chart type="line" height={300} options={perfomanceChart2Options} series={kpiAll.lineChart.axis} /> : "No Data"}
                        <div className="row">
                          <div className="col-lg-12 mb-3 mb-lg-0">
                            <div className="d-flex">
                              <div className="w-70px pt-3">
                                <Chart type="donut" height={70} options={DonatOptions()} series={kpiAll.resume.donutData} />
                              </div>
                              <div className="ps-3 flex-1">
                                <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">{kpiAll.resume.title}</div>
                                <div className="mb-2 fs-5 text-truncate">{kpiAll.resume.kpi}</div>
                                <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                                  <div className="progress-bar bg-theme" style={{ width: kpiAll.resume.progress }}></div>
                                </div>
                                <div className="fs-11px text-inverse text-opacity-50 mb-4 text-truncate">{kpiAll.resume.updated}</div>
                                {kpiAll.resume.info
                                  ? kpiAll.resume.info.map((info, index2) => (
                                      <div className="d-flex align-items-center small" key={index2}>
                                        <i className="bi bi-circle-fill fs-6px me-2 "></i>
                                        <div className="flex-1">{info.index}</div>
                                        <div>{info.value}</div>
                                      </div>
                                    ))
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))
                : "No Data"}
            </div>
          </CardBody>
        </Card>
      </div>

      {/* ------------------------------------End KPI All-------------------------------------- */}

      {/* ------------------------------------KPI Individual-------------------------------------- */}

      <div id="individualKpi">
        <h3>Individual Performance</h3>
        <p>This section displays individual performance</p>
        <div className="mb-3">{select.selectedState ? <Select1 classNamePrefix={"react-select"} onChange={(e) => changeInstructor(e)} isSearchable options={instructor} /> : ""}</div>
        {individualPerformance
          ? individualPerformance.map((indKpi, nomor) => (
              <div className="mb-4" key={nomor}>
                <Card>
                  <CardBody>
                    <h3 className="mb-3">{indKpi.nama}</h3>
                    <div className="row">
                      {indKpi.data
                        ? indKpi.data.map((kpiAll, index) => (
                            <div className="col-xl-12 mb-3" key={index}>
                              <h5>{kpiAll.title}</h5>
                              <div className="row">
                                <div className="col-xl-4">{kpiAll.barChart ? <Chart type="bar" height={300} options={perfomanceChart1Options} series={kpiAll.barChart.axis} /> : "No Data"}</div>
                                <div className="col-xl-4">{kpiAll.barChart ? <Chart type="line" height={300} options={perfomanceChart2Options} series={kpiAll.lineChart.axis} /> : "No Data"}</div>
                                <div className="col-xl-4">
                                  <div className="row">
                                    <div className="col-lg-12 mb-3 mb-lg-0">
                                      <div className="d-flex">
                                        <div className="w-70px pt-3">
                                          <Chart type="donut" height={70} options={DonatOptions()} series={kpiAll.resume.donutData} />
                                        </div>
                                        <div className="ps-3 flex-1">
                                          <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">{kpiAll.resume.title}</div>
                                          <div className="mb-2 fs-5 text-truncate">{kpiAll.resume.kpi}</div>
                                          <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                                            <div className="progress-bar bg-theme" style={{ width: kpiAll.resume.progress }}></div>
                                          </div>
                                          <div className="fs-11px text-inverse text-opacity-50 mb-4 text-truncate">{kpiAll.resume.updated}</div>
                                          {kpiAll.resume.info
                                            ? kpiAll.resume.info.map((info, index2) => (
                                                <div className="d-flex align-items-center small" key={index2}>
                                                  <i className="bi bi-circle-fill fs-6px me-2 "></i>
                                                  <div className="flex-1">{info.index}</div>
                                                  <div>{info.value}</div>
                                                </div>
                                              ))
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                        : "No Data"}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))
          : ""}
      </div>

      {/* ------------------------------------End KPI Individual-------------------------------------- */}
    </>
  );
}

export default Kpi;
